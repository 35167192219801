import type {
  ShiftPosPermission_t,
  ManagementPermissions_t,
} from "@/assets/types/permissionType";

type BaseNavigation_t = {
  name: string;
  icon?: string;
  route: string;
  active?: boolean;
};

interface ManagementNavigation_t extends BaseNavigation_t {
  subMenus?: BaseNavigation_t[];
}
interface usePagePermission_t {
  pageShift: ComputedRef<boolean>;
  posNavigation: Ref<BaseNavigation_t[]>;
  managementNavigation: Ref<ManagementNavigation_t[]>;
}

const { posPermission, managementPermission, isOnePosPermissionActive } =
  useInitialLocalStore();
const shitPosPermission = computed<ShiftPosPermission_t | null>(
  () => posPermission.value?.["shift-pos"] || null
);

const pageShift: ComputedRef<boolean> = computed<boolean>(() => {
  return shitPosPermission.value
    ? Object.values(shitPosPermission.value).some((v: boolean) => v)
    : false;
});

const managementNavigation: Ref<ManagementNavigation_t[]> = ref([]);
const posNavigation: Ref<BaseNavigation_t[]> = ref([]);

watchEffect(() => {
  posNavigation.value = [
    {
      route: "/sale",
      icon: "chart-line",
      name: "หน้าหลัก",
      active: pageShift.value,
    },
    {
      route: "/sale/tables?zone=all",
      icon: "bags-shopping",
      name: "โต๊ะ",
      active:
        isOnePosPermissionActive?.value !== null &&
        isOnePosPermissionActive.value === "true",
    },
    {
      route: "/sale/takeaway",
      icon: "house-user",
      name: "สั่งอาหาร",
      active:
        isOnePosPermissionActive?.value !== null &&
        isOnePosPermissionActive.value === "true",
    },
    {
      route: "/sale/activity",
      icon: "timer",
      name: "กิจกรรม",
      active: shitPosPermission?.value?.activity,
    },
  ];

  // management
  const managementPermits: Partial<ManagementPermissions_t | null> =
    managementPermission.value;
  if (managementPermits) {
    // const beverage = managementPermits?.beverage;
    const {
      user,
      admin,
      branch,
      table,
      menu,
      material,
      beverage,
      shift,
      valet,
      report,
    } = managementPermits;
    managementNavigation.value = [
      // Report
      {
        name: "รายงาน",
        icon: "magnifying-glass-chart",
        route: "/",
        active: true,
        subMenus: [
          {
            name: "รายงานเปิด-ปิดกะ",
            route: "/management/report/shift",
            active: shift?.list.viewAny,
          },
          {
            name: "รายงานสินค้า",
            route: "/management/report/product",
            active: report?.view.sale,
          },
          {
            name: "รายงานพนักงาน",
            route: "/management/report/employee",
            active: report?.view["employee-sale"],
          },
          {
            name: "รายงานกิจกรรม",
            route: "/management/report/activity",
            active: report?.view.activity,
          },
          {
            name: "รายงานยอดขาย",
            route: "/management/report/summary",
            active: report?.view.summary,
          },
          {
            name: "รายงานสต็อก",
            route: "/management/report/stock",
            active: report?.view.stock,
          },
          {
            name: "รายงานประเภทการชำระ",
            route: "/management/report/payment",
            active: report?.view["payment-method"],
          },
        ],
      },
      // Branch
      {
        name: "สาขา",
        icon: "sitemap",
        route: "/",
        active: branch
          ? Object.entries(branch).some(([, v]) => v.viewAny)
          : false,
        subMenus: [
          {
            name: "กลุ่มสาขา",
            route: "/management/branch/group",
            active: branch?.group.viewAny,
          },
          {
            name: "เครื่องพิมพ์",
            route: "/management/branch/printer",
            active: branch?.printer.viewAny,
          },
          {
            name: "เอเย่นต์เครื่องพิมพ์",
            route: "/management/branch/printer/agent",
            active: branch?.["printer-agent"].viewAny,
          },
          {
            name: "ครัว",
            route: "/management/branch/kitchen",
            active: branch?.kitchen.viewAny,
          },
          {
            name: "การชำระเงิน",
            route: "/management/branch/payment",
            active: branch?.["payment-method"].viewAny,
          },
          {
            name: "สาขา",
            route: "/management/branch",
            active: branch?.list.viewAny,
          },
        ],
      },
      // Table
      {
        name: "โต๊ะ",
        icon: "table-picnic",
        route: "/",
        active: table
          ? Object.entries(table).some(([, v]) => v.viewAny)
          : false,
        subMenus: [
          {
            name: "โซนโต๊ะ",
            route: "/management/table/zone",
            active: table?.zone.viewAny,
          },
          {
            name: "โต๊ะ",
            route: "/management/table",
            active: table?.list.viewAny,
          },
        ],
      },
      // Menu
      {
        name: "เมนู",
        icon: "burger-soda",
        route: "/",
        active: menu ? Object.entries(menu).some(([, v]) => v.viewAny) : false,
        subMenus: [
          {
            name: "ประเภท",
            route: "/management/menu/type",
            active: menu?.type.viewAny,
          },
          {
            name: "หมวดหมู่",
            route: "/management/menu/group",
            active: menu?.group.viewAny,
          },
          {
            name: "เมนู",
            route: "/management/menu",
            active: menu?.list.viewAny,
          },
          {
            name: "หมวดหมู่ท็อปปิ้ง",
            route: "/management/menu/topping/group",
            active: menu?.["topping-group"].viewAny,
          },
          {
            name: "ท็อปปิ้ง",
            route: "/management/menu/topping",
            active: menu?.topping.viewAny,
          },
          {
            name: "หมวดหมู่โปรโมชั่น",
            route: "/management/menu/promotion/group",
            active: menu?.["promotion-group"].viewAny,
          },
          {
            name: "โปรโมชั่น",
            route: "/management/menu/promotion",
            active: menu?.promotion.viewAny,
          },
        ],
      },
      // Material
      {
        name: "วัตถุดิบ",
        icon: "box-open-full",
        route: "/",
        active:
          material?.group.viewAny ||
          material?.list.viewAny ||
          material?.stock.view,
        subMenus: [
          {
            name: "หมวดหมู่",
            route: "/management/material/group",
            active: material?.group.viewAny,
          },
          {
            name: "วัตถุดิบ",
            route: "/management/material",
            active: material?.list.viewAny,
          },
          {
            name: "สต็อกวัตถุดิบ",
            route: "/management/material/stock",
            active: material?.stock.view,
          },
        ],
      },
      // Beverage
      {
        name: "การฝากเครื่องดื่ม",
        icon: "hand-holding-box",
        route: "/",
        active: true,
        subMenus: [
          {
            name: "สแกน QR",
            route: "/management/keep/beverage/scan",
            active: beverage?.scan.deposit || beverage?.scan["take-out"],
          },
          {
            name: "สร้าง QR",
            route: "/management/keep/beverage/generate",
            active: beverage?.["bulk-generate"].generate,
          },
          {
            name: "รายการฝากเครื่องดื่ม",
            route: "/management/keep/beverage",
            active: beverage?.list.view,
          },
          {
            name: "โอนเครื่องดื่ม",
            route: "/management/keep/beverage/transfer/send",
            active: beverage?.transfer["send-out"],
          },
          {
            name: "รายการโอนเครื่องดื่ม",
            route: "/management/keep/beverage/transfer",
            active: beverage?.transfer.list,
          },
          {
            name: "แบรนด์เครื่องดื่ม",
            route: "/management/keep/beverage/brand",
            active: beverage?.brand.viewAny,
          },
          {
            name: "ตอบรับเครื่องดื่ม",
            route: "/management/keep/beverage/transfer/receive",
            active: beverage?.transfer.receive,
          },
        ],
      },
      // Valet
      {
        name: "การฝากรถ",
        icon: "hand-holding-box",
        route: "/",
        active: true,
        subMenus: [
          {
            name: "สร้างข้อมูลฝากรถ",
            route: "/management/keep/valet/generate",
            active: valet?.create,
          },
          {
            name: "สแกน QR",
            route: "/management/keep/valet/scan",
            active:
              valet?.scan.park ||
              valet?.scan["re-print"] ||
              valet?.scan["take-out"],
          },
          {
            name: "รายการฝากรถ",
            route: "/management/keep/valet",
            active: valet?.list.view,
          },
          {
            name: "สถานที่จอดรถ",
            route: "/management/keep/valet/place",
            active: valet?.["parking-place"].viewAny,
          },
          {
            name: "โซนจอดรถ",
            route: "/management/keep/valet/zone",
            active: valet?.["parking-zone"].viewAny,
          },
          {
            name: "แบรนด์รถยนต์",
            route: "/management/keep/valet/brand",
            active: valet?.["car-brand"].viewAny,
          },
        ],
      },
      // Terminal
      {
        name: "เทอร์มินัล",
        icon: "display",
        route: "/management/terminal",
        active: branch?.terminal.viewAny,
      },
      // User
      {
        name: "สมาชิก",
        icon: "user",
        route: "/management/user",
        active: user?.list.viewAny,
      },
      // Admin
      {
        name: "ผู้ดูแลระบบ",
        icon: "route-interstate",
        route: "/management/admin",
        active: admin?.list.viewAny,
      },
    ];
  }
});

export const usePagePermission = (): usePagePermission_t => {
  return {
    pageShift,
    posNavigation,
    managementNavigation,
  };
};
